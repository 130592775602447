import { GLOBAL_RATING_BOARDS } from '@/constants/rating.const';
import { Confirmation } from '@/enums/common.enum';
import { LanguageCode } from '@/enums/language-code.enum';
import {
  RatingAge,
  RatingBoard,
  RatingContentCategory,
  RatingStatus,
  VerifyStatus
} from '@/enums/rating.enum';
import type { Genre, ProductSettingInfo } from '@/types/product/product-model.type';
import type { ProductResponse } from '@/types/product/product-response.type';
import type {
  LangValuePair,
  RatingProductResponse,
  RatingProductsLaunchInfoResponse,
  ReleasedRatings,
  RequestRating
} from '@/types/rating/rating.response.type';
import type { GlobalRatingBoardType } from '@/types/rating/rating.type';

export const getCorrectRating = (
  requestRating?: RequestRating,
  releaseRating?: ReleasedRatings[]
): RequestRating | ReleasedRatings[] | undefined => {
  if (
    (!releaseRating || releaseRating.length === 0) &&
    (!requestRating || !requestRating.createdAt)
  ) {
    return;
  }

  // Release rating
  if (releaseRating && releaseRating.length > 0 && (!requestRating || !requestRating.createdAt)) {
    return releaseRating;
  }

  // Request rating
  if (
    requestRating &&
    (!releaseRating || releaseRating.length === 0 || (releaseRating && !releaseRating[0].createdAt))
  ) {
    return requestRating;
  }

  // Request rating status not passed
  const requestRatingStatusNotPassed =
    requestRating &&
    (requestRating.status === RatingStatus.SAVE ||
      isVerifyingStatus(
        requestRating.status,
        (requestRating as RequestRating).verifyInfo?.verifyStatus
      ) ||
      isVerifyRejectStatus(
        requestRating.status,
        (requestRating as RequestRating).verifyInfo?.verifyStatus
      ));

  // Direct rating
  if (
    releaseRating &&
    releaseRating.length > 0 &&
    releaseRating[0].directYn === Confirmation.YES &&
    !requestRatingStatusNotPassed
  ) {
    return releaseRating;
  }

  if (releaseRating && requestRatingStatusNotPassed) {
    return requestRating;
  }

  // Request rating status passed
  const requestRatingStatusPassed =
    requestRating &&
    isVerifyPassStatus(
      requestRating.status,
      (requestRating as RequestRating).verifyInfo?.verifyStatus
    );
  if (releaseRating && requestRatingStatusPassed) {
    return releaseRating;
  }

  // Request rating status rejected
  const requestRatingStatusRejected =
    requestRating &&
    requestRating.status === RatingStatus.REJECTED &&
    (requestRating as RequestRating).verifyInfo?.verifyStatus === VerifyStatus.REJECT;
  if (requestRatingStatusRejected) {
    return requestRating;
  }
};

export const getAgeRatingText = (ageRating?: RatingAge | string) => {
  if (ageRating) {
    if (ageRating === RatingAge.AllAges) {
      return 'studio.prj_prod_mngmt.rating_build_review.self_review.post_review_guide1_rating_all';
    } else {
      return 'studio.prj_prod_mngmt.rating_build_review.self_review.post_review_guide1_rating_by_age';
    }
  }
  return '';
};

export const getIARCRegionTextByValue = (value: string) => {
  const locate = GLOBAL_RATING_BOARDS.find(
    (item: GlobalRatingBoardType) => item.boardName === value
  )?.locale;
  return locate || '';
};

export const getIARCRatingTextByValue = (value: string, ratingBoardType: string) => {
  const ratingOption = GLOBAL_RATING_BOARDS.find(
    (item: GlobalRatingBoardType) => item.boardName === ratingBoardType
  )?.ratingOption;
  if (ratingOption) {
    const array = Object.entries(ratingOption);
    const found = array.find((item: [string, string]) => {
      return item[1] === value;
    });

    if (found) {
      return found[0];
    }
  }

  return '';
};

export const getRatingOptionByValue = (value: string): [string, string][] => {
  const ratingOption = GLOBAL_RATING_BOARDS.find(
    (item: GlobalRatingBoardType) => item.boardName === value
  )?.ratingOption;
  if (!ratingOption) {
    return [];
  }

  const options = Object.entries(ratingOption);
  if (value === RatingBoard.ClassInd) {
    options.sort((a: [string, string], b: [string, string]) => {
      if (a[0] === 'L') {
        return -1;
      }
      if (b[0] === 'L') {
        return 1;
      }
      return a[0].localeCompare(b[0]);
    });
  }

  return options;
};

export const convertRatingContentInfosToTranslationText = (contentInfos?: string[]): string[] => {
  if (!contentInfos) {
    return [];
  }

  return contentInfos.map((contentInfo: string) => {
    switch (contentInfo) {
      case RatingContentCategory.VIOLENT:
        return 'studio.prj_prod_mngmt.rating_build_review.self_review.appeal_content_violence';
      case RatingContentCategory.OBSCENE:
        return 'studio.prj_prod_mngmt.rating_build_review.self_review.appeal_content_sexuality';
      case RatingContentCategory.GAMBLING:
        return 'studio.prj_prod_mngmt.rating_build_review.self_review.appeal_content_gambling';
      case RatingContentCategory.HORROR:
        return 'studio.prj_prod_mngmt.rating_build_review.self_review.appeal_content_horror';
      case RatingContentCategory.VILIFICATION:
        return 'studio.prj_prod_mngmt.rating_build_review.self_review.appeal_content_lang';
      case RatingContentCategory.MEDICATION:
        return 'studio.prj_prod_mngmt.rating_build_review.self_review.appeal_content_drug';
      case RatingContentCategory.CRIME:
        return 'studio.prj_prod_mngmt.rating_build_review.self_review.appeal_content_crime';
      default:
        return '';
    }
  });
};

export const getChangedRatingInformation = (
  launchInfo?: RatingProductsLaunchInfoResponse,
  product?: ProductResponse
) => {
  const launchInfoFieldChanged = [];
  if (launchInfo && product) {
    const krRatingProductName = launchInfo.productNames.find(
      (item: LangValuePair) => item.lang === LanguageCode.Ko
    )?.value;
    const enRatingProductName = launchInfo.productNames.find(
      (item: LangValuePair) => item.lang === LanguageCode.En
    )?.value;
    const krRatingDeveloper = launchInfo.developers.find(
      (item: LangValuePair) => item.lang === LanguageCode.Ko
    )?.value;
    const ratingDefaultGenreTagNo = launchInfo.genreTagNo?.toString();

    const krProductName = product.infos.find(
      (lang: ProductSettingInfo) => lang.languageCd === LanguageCode.Ko
    )?.productName;
    const enProductName = product.infos.find(
      (lang: ProductSettingInfo) => lang.languageCd === LanguageCode.En
    )?.productName;
    const krDeveloper = product.infos.find(
      (lang: ProductSettingInfo) => lang.languageCd === LanguageCode.Ko
    )?.developer;
    const defaultGenreTagNo = product.genres
      .find((genre: Genre) => genre.defaultGenreYn === Confirmation.YES)
      ?.tagNo.toString();

    if (krRatingProductName !== krProductName || enRatingProductName !== enProductName) {
      launchInfoFieldChanged.push('studio.prj_prod.this_prod.home_product_setting_basic_msg2');
    }

    if (krRatingDeveloper !== krDeveloper) {
      launchInfoFieldChanged.push('studio.prj_prod.this_prod.home_product_setting_basic_msg3');
    }

    if (ratingDefaultGenreTagNo !== defaultGenreTagNo) {
      launchInfoFieldChanged.push(
        'studio.prj_prod_mngmt.rating_build_review.self_review.rating_reg.genre'
      );
    }
  }

  return launchInfoFieldChanged;
};

export const isVerifyingStatus = (status: RatingStatus, verifyStatus?: VerifyStatus): boolean => {
  return (
    status === RatingStatus.SAVE &&
    (verifyStatus === VerifyStatus.REQUEST || verifyStatus === VerifyStatus.START)
  );
};

export const isVerifyPassStatus = (status: RatingStatus, verifyStatus?: VerifyStatus): boolean => {
  return status === RatingStatus.SAVE && verifyStatus === VerifyStatus.PASS;
};

export const isVerifyRejectStatus = (
  status: RatingStatus,
  verifyStatus?: VerifyStatus
): boolean => {
  return (status === RatingStatus.SAVE || status === RatingStatus.NOT_AVAILABLE) && verifyStatus === VerifyStatus.REJECT;
};

export const isDirectInput = (ratingsProduct?: RatingProductResponse): boolean => {
  if (
    !ratingsProduct ||
    !ratingsProduct.releasedRatings ||
    ratingsProduct.releasedRatings.length === 0
  ) {
    return false;
  }
  return ratingsProduct.releasedRatings[0].directYn === Confirmation.YES;
};

export const isSelfReview = (ratingsProduct?: RatingProductResponse): boolean => {
  return !isDirectInput(ratingsProduct);
};
